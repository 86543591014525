<template>
    <div class="container container-mod">
        <form @submit.prevent="userLogin" class="form">
            <h3 class="pagetitle">Bienvenido</h3>

            <div class="form-group">
                <label class="nixLabel">Email</label>
                <input type="email" class="form-control form-control-lg" v-model="user.email" required/>
            </div>

            <div class="form-group">
                <label class="nixLabel" >Password</label>
                <input type="password" class="form-control form-control-lg" v-model="user.password" required/>
            </div>

            <button type="submit" class="surveybutton">Ingresar</button>

            <!-- <p class="forgot-password text-right mt-2 mb-4">
                <router-link class="nixLabel" to="/forgot-password">Olvidó su password?</router-link>
            </p> -->
        </form>
    </div>
</template>
<script>
import firebase from "firebase";

export default {
  data() {
    return {
      user: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    userLogin() {
        firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(() => {

          // var user = firebase.auth().currentUser;
          // if (user != null) {
          //   user.providerData.forEach(function (profile) {
          //     //console.log("  Email: " + profile.email);
          //   });
          // }
            this.$router.push('/')
        })
        .catch((error) => {
          alert(error.message);
          console.log('Error: ',error.message);
        });
    }
  }
};
</script>
