import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'
import EncuestaSalud from '../views/EncuestaSalud.vue'
import EncuestasConsulta from '../views/EncuestasConsulta.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'EncuestaSalud',
    component: EncuestaSalud,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/encuestaconsulta',
    name: 'EncuestasConsulta',
    component: EncuestasConsulta,
    meta: {
      requiresAuth: true
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
