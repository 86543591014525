<template>
  <div class="container container-mod">

    <!-- ------- Pregunta Inicial Start ------- -->
    <div id="preguntaInicial" v-if="pagina == 0" class="preguntaInicial">
      <div class="row">
          <div class="col-md-12">
            <h1 class="pagetitle">Encuesta Condiciones de Salud COVID-19</h1>
          </div>
      </div>

      <div class="row">
          <div class="col-md-12">

            <div class="pagedescription">
              La siguiente encuesta deberá ser diligenciada diariamente al iniciar las labores, con el fin de hacer seguimiento a las condiciones de salud de nuestros colaboradores, dando cumplimiento a las medidas impartidas por el gobierno nacional.
            </div>
            <div class="pagedescription">
              Autorizo el tratamiento de mis datos, para tomar acciones de control de COVID-19, de acuerdo con lo exigido en la ley y los protocolos de manejo y prevención del mismo.
            </div>
            <div class="pagedescription">
              Declaro que la información registrada es precisa y correcta.
            </div>

            <form id="preguntaInicial" class="form">

              <div class="form-group">
                <label for="emailUsuario" class="nixLabel">* Nombres y apellidos</label>
                <input id="emailUsuario" v-model="emailUsuario" value="" type="text" class="form-control nixInput" aria-describedby="emailUsuario" placeholder="" :readonly="true">
              </div>

              <div class="filaPregunta">
                <h2 class="nixH2">1.* Selecciona tu estado de salud actual</h2>
                <div class="inputGroup">
                  <input id="presentoSintomas" v-model="preguntaInicial" value="presentoSintomas" name="preguntaInicial" type="radio"/>
                  <label for="presentoSintomas">A) Presento síntomas</label>
                </div>
                <div class="inputGroup">
                  <input id="tuveContacto" v-model="preguntaInicial" value="tuveContacto" name="preguntaInicial" type="radio"/>
                  <label for="tuveContacto">B) Tuve contacto estrecho con persona confirmada o sospechosa de Covid.</label>
                </div>
                <div class="inputGroup">
                  <input id="ninguna" v-model="preguntaInicial" value="ninguna" name="preguntaInicial" type="radio"/>
                  <label for="ninguna">C) Ninguna de las anteriores.</label>
                </div>
                <div class="row">
                    <div class="col-md-12">
                      <button type="button" @click="siguientePagina(1)" name="button" class="surveybutton">Siguiente</button>
                    </div>
                </div>
              </div>
            </form>

          </div>
      </div>
    </div>
    <!-- ------- Pregunta Inicial End ------- -->

    <!-- ------- Pagina Presento Sintomas Start ------- -->
    <div id="paginaPresentoSintomas" v-if="pagina == 1 && preguntaInicial=='presentoSintomas' " class="preguntaInicial">
      <div class="row">
          <div class="col-md-12">
            <h1 class="pagetitle">Presento síntomas</h1>
          </div>
      </div>

      <div class="row">
          <div class="col-md-12">

            <form id="presentoSintomasForm" class="form">

              <div class="filaPregunta">
                <label class="nixLabel">2)* Si la Opción es <strong>Presento síntomas</strong> <br>Selecciona los síntomas que presentas </label>
                <div class="inputGroup">
                  <input id="option1" name="option1" v-model="presentoSintomas" value="A" type="checkbox"/>
                  <label for="option1">A) He presentado fiebre en las ultimas 24 horas, igual o mayor a 38° medida con termómetro.</label>
                </div>
                <div class="inputGroup">
                  <input id="option2" name="option2" v-model="presentoSintomas" value="B" type="checkbox"/>
                  <label for="option2">B) Tengo malestar general y/o dolor muscular.</label>
                </div>
                <div class="inputGroup">
                  <input id="option3" name="option3" v-model="presentoSintomas" value="C" type="checkbox"/>
                  <label for="option3">C) Tengo dolor de garganta.</label>
                </div>
                <div class="inputGroup">
                  <input id="option4" name="option4" v-model="presentoSintomas" value="D" type="checkbox"/>
                  <label for="option4">D) Siento perdida del olfato y/o el gusto.</label>
                </div>
                <div class="inputGroup">
                  <input id="option5" name="option5" v-model="presentoSintomas" value="E" type="checkbox"/>
                  <label for="option5">E) Tengo tos seca y persistente.</label>
                </div>
                <div class="inputGroup">
                  <input id="option6" name="option6" v-model="presentoSintomas" value="F" type="checkbox"/>
                  <label for="option6">F) Actualmente tengo dificultad para respirar.</label>
                </div>

              </div>
            </form>

            <div class="row">
                <div class="col-md-12">
                  <button type="button" @click="siguientePagina(0)" name="button" class="surveybutton">Anterior</button>
                  <button type="button" @click="siguientePagina(3)" name="button" class="surveybutton">Siguiente</button>
                </div>
            </div>

          </div>
      </div>
    </div>
    <!-- ------- Pagina Presento Sintomas End ------- -->


    <!-- ------- Pagina Tuve Contacto Start ------- -->
    <div id="paginaTuveContacto" v-if="pagina == 2 && preguntaInicial=='tuveContacto' " class="preguntaInicial">
      <div class="row">
          <div class="col-md-12">
            <h1 class="pagetitle">Tuve contacto</h1>
          </div>
      </div>

      <div class="row">
          <div class="col-md-12">

            <form id="tuveContactoForm" class="form">

              <div class="form-group">
                <label class="nixLabel">Tuve contacto estrecho con persona confirmada o sospechosa de Covid</label>
              </div>

              <div class="filaPregunta">
                <div class="form-group">
                  <label class="nixLabel">3)* ​Fecha de contacto con persona confirmada o sospechosa</label>
                  <div class="divIconoCalendar">
                    <i class="fa fa-calendar iconoCalendar" aria-hidden="true"></i>
                  </div>
                  <datepicker v-model="fechaContacto" :language="es"></datepicker>
                </div>
              </div>

              <div class="filaPregunta">
                <label class="nixLabel">4)* Persona de contacto estrecho</label>
                <div class="inputGroup">
                  <input id="optionFamiliar" name="optionFamiliar" v-model="personaContacto" value="Familiar" type="radio"/>
                  <label for="optionFamiliar">A) Familiar.</label>
                </div>
                <div class="inputGroup">
                  <input id="optionSocial" name="optionSocial" v-model="personaContacto" value="Social" type="radio"/>
                  <label for="optionSocial">B) Social.</label>
                </div>

                <div class="row">
                    <div class="col-md-12">
                      <button type="button" @click="siguientePagina(0)" name="button" class="surveybutton">Anterior</button>
                      <button type="button" @click="siguientePagina(3)" name="button" class="surveybutton">Siguiente</button>
                    </div>
                </div>

              </div>
            </form>

          </div>
      </div>
    </div>
    <!-- ------- Pagina Tuve Contacto End ------- -->


    <!-- ------- Pagina Acciones Tomadas Start ------- -->
    <div id="paginaTuveContacto" v-if="pagina == 3" class="preguntaInicial">
      <div class="row">
          <div class="col-md-12">
            <h1 class="pagetitle">Acciones Tomadas</h1>
          </div>
      </div>

      <div class="row">
          <div class="col-md-12">

            <form id="accionesTomadasForm" class="form">

              <div class="filaPregunta">
                <label class="nixLabel">5)* ¿Realizaste el reporte a tu entidad de salud (EPS)?</label>
                <div class="inputGroup">
                  <input id="optionReporteSi" name="optionReporteSi" v-model="reporteEPS" value="SI" type="radio"/>
                  <label for="optionReporteSi">A) SI.</label>
                </div>
                <div class="inputGroup">
                  <input id="optionReporteNo" name="optionReporteNo" v-model="reporteEPS" @change="checkReporteEPS($event)" value="NO" type="radio"/>
                  <label for="optionReporteNo">B) NO.</label>
                </div>
              </div>

              <div v-if="reporteEPS=='SI'" class="filaPregunta">
                <label class="nixLabel">6)* ¿Te programaron incapacidad o certificado de aislamiento?</label>
                <div class="inputGroup">
                  <input id="optionAislamientoSi" name="optionAislamientoSi" v-model="aislamiento" value="SI" type="radio"/>
                  <label for="optionAislamientoSi">A) SI.</label>
                </div>
                <div class="inputGroup">
                  <input id="optionAislamientoNo" name="optionAislamientoNo" v-model="aislamiento" value="NO" type="radio"/>
                  <label for="optionAislamientoNo">B) NO.</label>
                </div>
              </div>

              <div class="filaPregunta">
                <label class="nixLabel">7)* ¿Te programaron prueba para Covid-19?</label>
                <div class="inputGroup">
                  <input id="optionPruebaSi" name="optionPruebaSi" v-model="pruebaCovid" value="SI" type="radio"/>
                  <label for="optionPruebaSi">A) SI.</label>
                </div>
                <div class="inputGroup">
                  <input id="optionPruebaNo" name="optionPruebaNo" v-model="pruebaCovid" @change="checkPrueba($event)" value="NO" type="radio"/>
                  <label for="optionPruebaNo">B) NO.</label>
                </div>
                <div class="inputGroup">
                  <input id="optionPruebaYa" name="optionPruebaYa" v-model="pruebaCovid" @change="checkPrueba($event)" value="YA" type="radio"/>
                  <label for="optionPruebaYa">C) YA LA TOMÉ.</label>
                </div>
              </div>

              <div v-if="pruebaCovid=='SI'" class="filaPregunta">
                <div class="form-group">
                  <label class="nixLabel">8)* ¿Cual es la fecha de la futura prueba?</label>
                  <div class="divIconoCalendar">
                    <i class="fa fa-calendar iconoCalendar" aria-hidden="true"></i>
                  </div>
                  <datepicker v-model="fechaFuturaPrueba" :language="es"></datepicker>
                </div>
              </div>

              <div v-if="pruebaCovid=='YA'" class="filaPregunta">
                <label class="nixLabel">9)* ¿Cual fue el resultado de la prueba?</label>
                <div class="inputGroup">
                  <input id="optionResultadosPruebaPositivo" name="optionResultadosPruebaPositivo" v-model="resultadosPruebaCovid" value="POSITIVO" type="radio"/>
                  <label for="optionResultadosPruebaPositivo">A) POSITIVO.</label>
                </div>
                <div class="inputGroup">
                  <input id="optionResultadosPruebaNegativo" name="optionResultadosPruebaNegativo" v-model="resultadosPruebaCovid" value="NEGATIVO" type="radio"/>
                  <label for="optionResultadosPruebaNegativo">B) NEGATIVO.</label>
                </div>
                <div class="inputGroup">
                  <input id="optionResultadosPruebaNoEntregados" name="optionResultadosPruebaNoEntregados" v-model="resultadosPruebaCovid" value="NoEntregados" type="radio"/>
                  <label for="optionResultadosPruebaNoEntregados">C) Aún no entregados.</label>
                </div>
              </div>

            </form>

            <div class="row">
                <div class="col-md-12">
                  <button type="button" @click="siguientePagina(0)" name="button" class="surveybutton">Anterior</button>
                  <button type="button" @click="siguientePagina(4)" name="button" class="surveybutton">Enviar</button>
                </div>
            </div>

          </div>
      </div>
    </div>
    <!-- ------- Pagina Acciones Tomadas End ------- -->



    <!-- ------- Pagina Gracias Start ------- -->
    <div id="paginaGracias" v-if="pagina == 4" class="preguntaInicial">
      <div class="row">
          <div class="col-md-12">
            <h1 class="pagetitle">Muchas gracias.</h1>
            <div v-if="enviado">
              <label class="nixLabelCenter">Ya puedes cerrar esta encuesta.</label>
              <div class="lds-heart"><div></div></div>
            </div>
            <div v-else="enviado">
              <label class="nixLabelCenter">Estamos enviando las respuestas.</label>
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
      </div>
    </div>
    <!-- ------- Pagina Gracias End ------- -->


	</div>
</template>
<script>
import firebase, { storage } from "firebase";
import Datepicker from 'vuejs-datepicker';
import {en, es} from 'vuejs-datepicker/dist/locale';
import moment from 'moment';

  export default {
    name: 'EncuestaSalud',
    components: {
      Datepicker
    },
    data() {
      return {
        emailUsuario: "",
        // cedula: "",
        preguntaInicial: "",
        presentoSintomas: [],
        personaContacto: "",
        fechaContacto: "",
        pagina: 0,
        reporteEPS: "",
        aislamiento: "",
        pruebaCovid: "",
        fechaFuturaPrueba: "",
        resultadosPruebaCovid: "",
        enviado: false,
        todayTimestamp: "",
        idEncuesta: "",
        en: en,
        es: es
      };
    },

    /* --- Methods Start --- */
    methods:{

      siguientePagina(numPagina){
        if( numPagina==0 )
        {
          this.pagina=0;
          this.preguntaInicial = "";
          this.presentoSintomas = [];
          this.personaContacto = "";
          this.fechaContacto = "";
          this.reporteEPS = "";
          this.aislamiento = "";
          this.pruebaCovid = "";
          this.fechaFuturaPrueba = "";
          this.resultadosPruebaCovid = "";
          // console.log('pagina: ',this.pagina);
        }
        if( numPagina==1 && this.preguntaInicial=='presentoSintomas' && this.informacionPersonal )
        {
          this.pagina=1;
          // console.log('pagina: ',this.pagina);
        }
        if( numPagina==1 && this.preguntaInicial=='tuveContacto' && this.informacionPersonal )
        {
          this.pagina=2;
          // console.log('pagina: ',this.pagina);
        }
        if( numPagina==1 && this.preguntaInicial=='ninguna' && this.informacionPersonal )
        {
          this.pagina=4;
          // console.log('pagina 4: ',this.pagina);
          this.registrarEncuesta();
        }
        // if( numPagina==3 && (this.preguntaInicial==='tuveContacto' || this.preguntaInicial==='presentoSintomas') )
        if( numPagina==3 && (this.preguntaInicial==='presentoSintomas' && this.presentoSintomas.length > 0) )
        {
          this.pagina=3;
          // console.log('pagina: ',this.pagina);
          // console.log('presentoSintomas: ', this.presentoSintomas.length );
        }
        if( numPagina==3 && (this.preguntaInicial==='tuveContacto' && this.fechaContacto && this.personaContacto) )
        {
          this.pagina=3;
          // console.log('pagina: ',this.pagina);
          // console.log('this.fechaContacto: ',this.fechaContacto);
          // console.log('this.personaContacto: ',this.personaContacto);
        }

        if( numPagina==4 && this.reporteEPS && this.pruebaCovid )
        {
          // console.log('this.reporteEPS: ',this.reporteEPS);
          // console.log('this.pruebaCovid: ',this.pruebaCovid);
          // console.log('this.fechaFuturaPrueba: ',this.fechaFuturaPrueba);

          if( this.pruebaCovid=='SI' && this.fechaFuturaPrueba )
          {
            this.pagina=4;
            // console.log('pagina: ',this.pagina);
            // console.log('this.fechaFuturaPrueba: ',this.fechaFuturaPrueba);
            // -- ENVIAR
            this.registrarEncuesta();
          }
          if( this.pruebaCovid=='NO' )
          {
            this.pagina=4;
            // console.log('pagina: ',this.pagina);
            // -- ENVIAR
            this.registrarEncuesta();
          }
          if( this.pruebaCovid=='YA' && this.resultadosPruebaCovid )
          {
            this.pagina=4;
            //console.log('pagina: ',this.pagina);
            // console.log('this.resultadosPruebaCovid: ',this.resultadosPruebaCovid);
            // -- ENVIAR
            this.registrarEncuesta();
          }
        }

      },

      /* ---- REGISTER NEW ENCUESTA Start ---- */
      registrarEncuesta(){
        firebase
          .firestore()
          .collection("encuestaSalud-2021-01")
          .add({
            emailUsuario: this.emailUsuario,
            // cedula: this.cedula,
            preguntaInicial: this.preguntaInicial,
            presentoSintomas: this.presentoSintomas.toString(),
            personaContacto: this.personaContacto,
            fechaContacto: this.fechaContacto,
            reporteEPS: this.reporteEPS,
            aislamiento: this.aislamiento,
            pruebaCovid: this.pruebaCovid,
            fechaFuturaPrueba: this.fechaFuturaPrueba,
            resultadosPruebaCovid: this.resultadosPruebaCovid,
            todayTimestamp: this.todayTimestamp
            // unidadCerrada: this.newProperty.unidadCerrada,
            // propertyAmenities: this.newProperty.propertyAmenities.toString(),
            // communityAmenities: this.newProperty.communityAmenities.toString(),
            // mainImage: "noImage"
          }).then(docRef => {

            this.idEncuesta = docRef.id;
            this.enviado = true;
            // console.log("Document written with ID: ", docRef.id);

          }).catch(function(error) {
            console.error("Error adding document: ", error);
          });
      },
      /* ---- REGISTER NEW ENCUESTA End ---- */

      checkReporteEPS(e){
        this.aislamiento = "";
      },
      checkPrueba(e){
        this.fechaFuturaPrueba = "";
      },

      getNow(){
        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // const dateTime = date +' '+ time;
        const dateTime = date;
        this.todayTimestamp = dateTime;
        // this.todayTimestamp = this.customFormatter(dateTime);
        // this.customFormatter(date);
        console.log('INICIAL: ', this.todayTimestamp);
      },

      customFormatter(date){
        // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        return moment(date).format('DD-MM-YYYY');
      },
      customFormatterfechaContacto(date){
        this.fechaContacto = moment(date).format('DD-MM-YYYY');
        // console.log('this.fechaContacto: ',this.fechaContacto);
        return moment(date).format('DD-MM-YYYY');
      }




      /* ---- getData Start ---- */
    //   getData(id="ClRTVdUCrMfhMujUp6sP") {
    //     var docRef = firebase.firestore().collection("encuestaSalud-2021-01").doc(id);
    //     docRef.get().then( (doc)=> {
    //         if (doc.exists) {
    //             console.log("CONSULTA: ",doc.data().nombre);
    //         } else {
    //             console.log("No such document!");
    //         }
    //     }).catch(function(error) {
    //         console.log("Error getting document:", error);
    //     });
    //
    // }
    /* ---- getData End ---- */


    },
    /* --- Methods End --- */

    mounted(){

      $('.js-tilt').tilt({
        scale: 1.1
      });


      this.getNow();

      // --- Get User Email
      var user = firebase.auth().currentUser;
      //console.log('user ',user);
      if (user != null) {
        user.providerData.forEach( (profile) => {
          this.emailUsuario = profile.email;
          //console.log("Email: " + this.emailUsuario);

          // ---- Verificar si ya realizó la encuesta
          // firebase.firestore().collection("encuestaSalud-2021-01")
          // // .where("emailUsuario", "==", "nixon.osorio@zibordesarrolladores.com")
          // // .where("todayTimestamp", "==", this.todayTimestamp)
          // .where("emailUsuario", "==", this.emailUsuario)
          // .where("todayTimestamp", "==", "2021-1-22")
          // .get()
          // .then( (doc)=> {
          //     console.log("doc.data(): ",doc);
          //     if (doc.exists) {
          //         console.log("CONSULTA: ",doc.data().todayTimestamp);
          //     } else {
          //         console.log("NO EXISTE!");
          //     }
          // }).catch(function(error) {
          //     console.log("Error getting document:", error);
          // });

        });
      }
      // --- /Get User Email

    },

    computed:{
      informacionPersonal(){
        // return this.emailUsuario && this.cedula;
        return this.emailUsuario;
      }
    }

  }
</script>
