<template>
  <div class="container-fluid container-mod">

  <div class="row mb-12" v-if="this.estadoAutorizado === false">
    <div class="col-md-12">
      <h1 class="pagetitle">Verificando acceso...</h1>
    </div>
  </div>

  <div class="row mb-12" v-if="this.estadoAutorizado">
    <div class="col-md-12">
      <nav role="navigation">
        <div id="menuToggle">

          <input type="checkbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <a @click="consultarMes('1','2')" href="#"><li>Enero</li></a>
            <a @click="consultarMes('2','3')" href="#"><li>Febrero</li></a>
            <a @click="consultarMes('3','4')" href="#"><li>Marzo</li></a>
            <a @click="consultarMes('4','5')" href="#"><li>Abril</li></a>
            <a @click="consultarMes('5','6')" href="#"><li>Mayo</li></a>
            <a @click="consultarMes('6','7')" href="#"><li>Junio</li></a>
            <a @click="consultarMes('7','8')" href="#"><li>Julio</li></a>
            <a @click="consultarMes('8','9')" href="#"><li>Agosto</li></a>
            <a @click="consultarMes('9','10')" href="#"><li>Septiembre</li></a>
            <a @click="consultarMes('10','11')" href="#"><li>Octubre</li></a>
            <a @click="consultarMes('11','12')" href="#"><li>Noviembre</li></a>
            <a @click="consultarMes('12','1')" href="#"><li>Diciembre</li></a>
          </ul>
        </div>
      </nav>
    </div>
  </div>

  <div class="row" v-if="this.estadoAutorizado">
      <div class="col-md-12 moddataTable columnaSintomas">
        <datatable
          title="Resultados encuesta 2021"
          :columns="this.tableColumns1"
          :rows="this.tableRows1"
          :searchable="false"
          :printable="false"
          :exportable="true"
          >
        </datatable>
      </div>
  </div>



	</div>
</template>
<script>
import firebase, { storage } from "firebase";
import DataTable from "vue-materialize-datatable";
var CryptoJS = require("crypto-js");

  export default {
    name: 'EncuestasConsulta',
    components: {
      "datatable": DataTable
    },
    data() {
      return {

        emailUsuarioAutorizado: "",
        estadoAutorizado: false,
        emailUsuario: "",
        cedulaUsuario: "",
        cedulaUsuarioEncrypted: "",
        listaSintomas: [],
        // porPaginaci: [10, 20, 30, 40, 50],
        // defectoPerPage: 22,
        tableColumns1: [
          {
            label: "Email",
            field: "emailUsuario",
            numeric: false,
            html: false
          },
          {
            label: "Pregunta inicial",
            field: "preguntaInicial",
            numeric: false,
            html: false
          },
          {
            label: "Presento sintomas",
            field: "presentoSintomas",
            numeric: false,
            html: false
          },
          {
            label: "Fecha contacto",
            field: "fechaContacto",
            numeric: false,
            html: false
          },
          {
            label: "Persona contacto",
            field: "personaContacto",
            numeric: false,
            html: false
          },
          {
            label: "Prueba covid",
            field: "pruebaCovid",
            numeric: false,
            html: false
          },
          {
            label: "Aislamiento",
            field: "aislamiento",
            numeric: false,
            html: false
          },
          {
            label: "Fecha futura prueba",
            field: "fechaFuturaPrueba",
            numeric: false,
            html: false
          },
          {
            label: "Reporte EPS",
            field: "reporteEPS",
            numeric: false,
            html: false
          },
          {
            label: "Resultados prueba covid",
            field: "resultadosPruebaCovid",
            numeric: false,
            html: false
          },
          {
            label: "Fecha encuesta",
            field: "todayTimestamp",
            numeric: false,
            html: false
          }
        ],
        tableRows1:[],
      };
    },

    /* --- Methods Start --- */
    methods:{

      encrypt(){
      },
      /* ---- CONSULTAR MES Start ---- */
      consultarMes(mesInicio,mesFin){
        // let startDate = new Date('2021-01-01');
        // let endDate = new Date('2021-2-01');
        firebase.firestore().collection("encuestaSalud-2021-01")
        // .where("emailUsuario", "==", "administrativo@zibordesarrolladores.com")
        .where("todayTimestamp", ">=", "2021-"+mesInicio+"-01")
        .where("todayTimestamp", "<", "2021-"+mesFin+"-01")
        .get()
        .then( (querySnapshot) => {
        // .then(function(querySnapshot) {
            // querySnapshot.forEach(function(doc) {
            this.tableRows1 = []; // ---- Clear this.tableRows1
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // const t = firebase.firestore.Timestamp.fromDate(new Date());
                //const t = firebase.firestore.Timestamp.fromMillis( doc.data().fechaContacto );
                /* ---- FECHAS Start ---- */
                let fechaContacto = '';
                let fechaFuturaPrueba = '';
                let todayTimestamp = '';
                if( doc.data().fechaContacto != '' ){
                  // fechaContacto = doc.data().fechaContacto.toDate().toDateString();
                  let fechaContactoTMP = doc.data().fechaContacto.toDate();
                  fechaContacto = fechaContactoTMP.getDate()+'-'+(fechaContactoTMP.getMonth()+1)+'-'+fechaContactoTMP.getFullYear();
                }
                if( doc.data().fechaFuturaPrueba != '' ){
                  let fechaFuturaPruebaTMP = doc.data().fechaFuturaPrueba.toDate();
                  fechaFuturaPrueba = fechaFuturaPruebaTMP.getDate()+'-'+(fechaFuturaPruebaTMP.getMonth()+1)+'-'+fechaFuturaPruebaTMP.getFullYear();
                }
                if( doc.data().todayTimestamp != '' ){
                  let todayTimestampTMP = doc.data().todayTimestamp.toString().split('-');
                  todayTimestamp = todayTimestampTMP[2]+'-'+todayTimestampTMP[1]+'-'+todayTimestampTMP[0];
                }
                /* ---- FECHAS End ---- */

                /* ---- SINTOMAS Start ---- */
                if( doc.data().presentoSintomas ){
                  var listaSintomasCompleta = '';
                  // let listaSintomasCompletaTMP = doc.data().presentoSintomas.split(',');
                  // console.log('split: ',listaSintomasCompletaTMP[0]);
                  listaSintomasCompleta = this.returnListaSintomas(doc.data().presentoSintomas);
                  //console.log('listaSintomasCompleta: ', listaSintomasCompleta);
                } else {
                  var listaSintomasCompleta = ' ';
                }
                //console.log("nix--->: ",doc.data().presentoSintomas.length);
                /* ---- SINTOMAS End ---- */

                this.tableRows1.push({
                  'emailUsuario': doc.data().emailUsuario,
                  'preguntaInicial': doc.data().preguntaInicial,
                  // 'presentoSintomas': doc.data().presentoSintomas,
                  'presentoSintomas': listaSintomasCompleta,
                  'fechaContacto': fechaContacto,
                  'personaContacto': doc.data().personaContacto,
                  'pruebaCovid': doc.data().pruebaCovid,
                  'aislamiento': doc.data().aislamiento,
                  'fechaFuturaPrueba': fechaFuturaPrueba,
                  'reporteEPS': doc.data().reporteEPS,
                  'resultadosPruebaCovid': doc.data().resultadosPruebaCovid,
                  'todayTimestamp': todayTimestamp
                 });
                // console.log(doc.id, " => ", doc.data());
                //console.log( doc.id, " => ", doc.data().emailUsuario );
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });

        /* --------- DICIEMBRE Consulta unica Start --------- */
        if (mesInicio==12 && mesFin==1) {
          firebase.firestore().collection("encuestaSalud-2021-01")
          // .where("emailUsuario", "==", "administrativo@zibordesarrolladores.com")
          .where("todayTimestamp", ">=", "2021-"+mesInicio+"-01")
          .where("todayTimestamp", "<", "2022-"+mesFin+"-01")
          .get()
          .then( (querySnapshot) => {
          // .then(function(querySnapshot) {
              // querySnapshot.forEach(function(doc) {
              this.tableRows1 = []; // ---- Clear this.tableRows1
              querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  // const t = firebase.firestore.Timestamp.fromDate(new Date());
                  //const t = firebase.firestore.Timestamp.fromMillis( doc.data().fechaContacto );
                  /* ---- FECHAS Start ---- */
                  let fechaContacto = '';
                  let fechaFuturaPrueba = '';
                  let todayTimestamp = '';
                  if( doc.data().fechaContacto != '' ){
                    // fechaContacto = doc.data().fechaContacto.toDate().toDateString();
                    let fechaContactoTMP = doc.data().fechaContacto.toDate();
                    fechaContacto = fechaContactoTMP.getDate()+'-'+(fechaContactoTMP.getMonth()+1)+'-'+fechaContactoTMP.getFullYear();
                  }
                  if( doc.data().fechaFuturaPrueba != '' ){
                    let fechaFuturaPruebaTMP = doc.data().fechaFuturaPrueba.toDate();
                    fechaFuturaPrueba = fechaFuturaPruebaTMP.getDate()+'-'+(fechaFuturaPruebaTMP.getMonth()+1)+'-'+fechaFuturaPruebaTMP.getFullYear();
                  }
                  if( doc.data().todayTimestamp != '' ){
                    let todayTimestampTMP = doc.data().todayTimestamp.toString().split('-');
                    todayTimestamp = todayTimestampTMP[2]+'-'+todayTimestampTMP[1]+'-'+todayTimestampTMP[0];
                  }
                  /* ---- FECHAS End ---- */

                  /* ---- SINTOMAS Start ---- */
                  if( doc.data().presentoSintomas ){
                    var listaSintomasCompleta = '';
                    // let listaSintomasCompletaTMP = doc.data().presentoSintomas.split(',');
                    // console.log('split: ',listaSintomasCompletaTMP[0]);
                    listaSintomasCompleta = this.returnListaSintomas(doc.data().presentoSintomas);
                    //console.log('listaSintomasCompleta: ', listaSintomasCompleta);
                  }
                  /* ---- SINTOMAS End ---- */

                  this.tableRows1.push({
                    'emailUsuario': doc.data().emailUsuario,
                    'preguntaInicial': doc.data().preguntaInicial,
                    // 'presentoSintomas': doc.data().presentoSintomas,
                    'presentoSintomas': listaSintomasCompleta,
                    'fechaContacto': fechaContacto,
                    'personaContacto': doc.data().personaContacto,
                    'pruebaCovid': doc.data().pruebaCovid,
                    'aislamiento': doc.data().aislamiento,
                    'fechaFuturaPrueba': fechaFuturaPrueba,
                    'reporteEPS': doc.data().reporteEPS,
                    'resultadosPruebaCovid': doc.data().resultadosPruebaCovid,
                    'todayTimestamp': todayTimestamp
                   });
                  // console.log(doc.id, " => ", doc.data());
                  //console.log( doc.id, " => ", doc.data().emailUsuario );
              });
          })
          .catch(function(error) {
              console.log("Error getting documents: ", error);
          });
        }
        /* --------- DICIEMBRE Consulta unica End --------- */

        // var docRef = firebase.firestore().collection("encuestaSalud-2021-01").doc(id);
        // docRef.get().then( (doc)=> {
        //     if (doc.exists) {
        //         console.log("CONSULTA: ",doc.data().nombre);
        //     } else {
        //         console.log("No such document!");
        //     }
        // }).catch(function(error) {
        //     console.log("Error getting document:", error);
        // });
      },
      /* ---- CONSULTAR MES End ---- */

      /* ---- CONSULTAR SINTOMAS Start ---- */
      consultarSintomas(){
        firebase.firestore().collection("listaSintomas").doc("sintomas")
        .get()
        .then( (doc) => {
          if (doc.exists) {
            //console.log("Document data:", doc.data());
            this.listaSintomas.push(
              { key: "A", value: doc.data().A },
              { key: "B", value: doc.data().B },
              { key: "C", value: doc.data().C },
              { key: "D", value: doc.data().D },
              { key: "E", value: doc.data().E },
              { key: "F", value: doc.data().F }
             );
          }
          // console.log("Document data:", this.listaSintomas);
          // console.log("Document data:", this.listaSintomas[0].value);
          // console.log("Document data:", this.listaSintomas[1].key);
        })
        .catch(function(error) {
            console.log("Error getting Sintomas: ", error);
        });
        // console.log( "this.listaSintomas: ", this.listaSintomas );
        //console.log( " => ", this.listaSintomas[0] );
      },
      /* ---- CONSULTAR SINTOMAS End ---- */
      /* ---- DEVOLDER LISTA SINTOMAS Start ---- */
      buscar(nameKey, myArray){
          for (var i=0; i < myArray.length; i++) {
              if (myArray[i].key === nameKey) {
                  return myArray[i].value;
              }
          }
      },
      returnListaSintomas(cortar){
        let listaSintomasTMP = cortar.split(',');
        let mensajesTMP = "";
        for(var k = 0; k < listaSintomasTMP.length; k++){
          //console.log("BUSCANDO: ",listaSintomasTMP[k]);
          mensajesTMP += this.buscar(listaSintomasTMP[k], this.listaSintomas)+" ";
        }
        return mensajesTMP;
      },
      /* ---- DEVOLDER LISTA SINTOMAS End ---- */

      /* ---- getData Start ---- */
    //   getData(id="ClRTVdUCrMfhMujUp6sP") {
    //     var docRef = firebase.firestore().collection("encuestaSalud-2021-01").doc(id);
    //     docRef.get().then( (doc)=> {
    //         if (doc.exists) {
    //             console.log("CONSULTA: ",doc.data().nombre);
    //         } else {
    //             console.log("No such document!");
    //         }
    //     }).catch(function(error) {
    //         console.log("Error getting document:", error);
    //     });
    //
    // }
    /* ---- getData End ---- */


    /* ---- AUTENTICAR ADMIN Start ---- */
    autenticarAdmin(){
      // --- Get User Email
      let userActua = firebase.auth().currentUser;
      if (userActua != null) {
        userActua.providerData.forEach( (profile) => {
          this.emailUsuarioAutorizado = profile.email;
          //console.log("Email: " + this.emailUsuarioAutorizado);
          //---- Luego de obtener el usuario actual, verificar que sea administrador
          firebase.firestore().collection("detalleUsuario").doc(this.emailUsuarioAutorizado)
          .get().then( (doc)=> {
            if (doc.exists) {
                // console.log("CONSULTA: ",doc.data());
                //console.log("CONSULTA: ",doc.data().tipoUsuario );
                if(doc.data().tipoUsuario == "admin"){
                  this.estadoAutorizado = true;
                } else {
                  this.estadoAutorizado = false;
                }
            } else {
                console.log("No such document!");
            }
          })
          .catch(function(error) {
              console.log("Error getting documents: ", error);
          });

        });
      }
    },
    /* ---- AUTENTICAR ADMIN End ---- */

    },
    /* --- Methods End --- */

    mounted(){
      this.autenticarAdmin();
      this.consultarSintomas();
      // this.consultarMes('1','2');
    },

    computed:{

    }

  }
</script>
