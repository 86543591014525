//Tomado desde https://savvyapps.com/blog/definitive-guide-building-web-app-vuejs-firebase
//https://blog.logrocket.com/how-to-build-and-deploy-a-vue-js-crud-app-with-cloud-firestore-and-firebase/

import firebase from "firebase/app";
// firebase.initializeApp({ ... })

// import * as firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1QNwOu-Hd5MGXfw9E4riWGmEcrcBKqmQ",
  authDomain: "encuestaszibor.firebaseapp.com",
  projectId: "encuestaszibor",
  storageBucket: "encuestaszibor.appspot.com",
  messagingSenderId: "949762283207",
  appId: "1:949762283207:web:20eabeabd3c3000d5650e2",
  measurementId: "G-JDZ9M70HJR"
};
firebase.initializeApp(firebaseConfig);
// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
// const usersCollection = db.collection('users');
// const encuestaSalud = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth
  // usersCollection
  // postsCollection,
  // commentsCollection,
  // likesCollection
}
